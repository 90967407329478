import React, {useEffect, useState} from "react"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import Modal from "./Modal/Modal"
import "./Vefatlar.css"
import {Config} from "../../Config/config"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
import Pagination from "../../Components/Pagination/Pagination"
const Vefatlar = () => {
  const [vefatlar, setVefatlar] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage] = useState(3)
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    fetchVefatlar()
  }, [searchQuery, currentPage])

  const fetchVefatlar = () => {
    //  console.log("fetch atılıyor");
    const url = searchQuery
      ? `${Config.ApiBaseUrl}/deaths/?is_active=true&search=${searchQuery}`
      : `${Config.ApiBaseUrl}/deaths/?is_active=true&page=${currentPage}`

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${Config.Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Veri alınamadı")
        }
        return response.json()
      })
      .then((data) => {
        setVefatlar(data.results || [])
        setTotalPages(data.total_pages || 1)
      })
      .catch((error) => console.error("Hata:", error))
  }

  const openModal = (id) => {
    const selectedVefat = vefatlar.find((vefat) => vefat.id === id)
    setSelectedModal(selectedVefat || null)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedModal(null)
  }

  return (
    <>
      <TopRelatedCategory />
      <div className="Vefatlarbottom">
        <div className="vefatlar-left-side-container">
          <TitleBox title="Vefatlar" />
          <div className="searchbar-vefatlar">
            <div className="searchbar-vefatlar-item-one">Vefatlar</div>
            <div className="searchbar-vefatlar-item-two">
              <input
                type="text"
                className="search-input"
                placeholder="Vefat adı ara..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="search-button" onClick={() => fetchVefatlar()}>
                Ara
              </button>
            </div>
          </div>
          <div className="Vefatlar-container">
            <div className="Vefatlar-container-first-wrapper">
              <div className="Vefatlar-table-container">
                {vefatlar.length > 0 ? (
                  <table className="vefatlar-table">
                    <thead>
                      <tr>
                        <th>Ad Soyad</th>
                        <th>Yeri</th>
                        <th>Tarih</th>
                        <th>Görsel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vefatlar.map((vefat) => {
                        const deathDate = new Date(vefat.death_date)
                        const formattedDate =
                          deathDate.toLocaleDateString("tr-TR") // 27.09.2024 formatı
                        return (
                          <tr key={vefat.id}>
                            <td>{vefat.fullname}</td>
                            <td>{vefat.location__name}</td>
                            <td>{formattedDate}</td>
                            <td>
                              <button onClick={() => openModal(vefat.id)}>
                                İlan Görselli
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p>Henüz vefat kaydı bulunmamaktadır.</p>
                )}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>

            <SingleRelatedComponent />
          </div>
        </div>
        <div className="Vefatlar-sidebar">
          <Side />
        </div>

        {/* Modal Bileşeni */}
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          selectedModal={selectedModal}
        />
      </div>
    </>
  )
}

export default Vefatlar
