import React from "react"
import "./Yayin_ilkeleri.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
const YayinIlkeleri = () => {
  return (
    <>
      <TopRelatedCategory />
      <div className="yayinIlkeleri-Left">
        <div className="yayinIlkeleri-Left-ad">
          <TitleBox title="Yayın İlkeleri" />
          <div className="yayinIlkeleri-Left-line"></div>
          <div className="yayinIlkeleri-Left-h1">
            Kıbrıslı Gazetesi Yayın İlkeleri
          </div>
          <div className="yayinIlkeleri-Left-h1">Giriş</div>
          <div className="yayinIlkeleri-Left-content">
            <p className="yayinIlkeleri-Left-text">
              Kıbrıslı Gazetesi, "amacını toplum yaşamına katıldığı 7 Mayıs
              1924'te yayınladığı ilk sayısında kurucusu Lorem Ipsum'un
              kalemiyle belirlemiştir. Kıbrıslı Gazetesi, ne hükümet ne de parti
              gazetesidir. Kıbrıslı yalnız Kıbrıslılar'ın, bilimsel ve yaygın
              anlatımıyla demokrasinin savunucusudur. Kıbrıslı, demokrasi fikir
              ve esaslarını yıkmaya çalışan her kuvvete karşı mücadele
              edecektir. Ülkemizde her anlamıyla gerçek bir demokrasi kurulması
              için bütün varlığı ile çalışacaktır.
            </p>
            <p className="yayinIlkeleri-Left-text">
              Şirket, kişisel verileri, internet siteleri, sosyal medya
              hesapları, e-posta, posta, call center, fax, idari ve adli
              makamlardan gelen tebligatlar ve sair iletişim kanalları
              aracılığıyla işitsel, elektronik veya yazılı olarak, Kanun’da
              belirtilen kişisel veri işleme şartlarına uygun olarak ve bu
              Metin’de belirtilen hukuki sebepler ve amaçlar doğrultusunda
              toplamaktadır.
            </p>
          </div>

          <div className="yayinIlkeleri-Left-h1">1-Genel Hükümler</div>

          <div className="yayinIlkeleri-Left-content">
            <p className="yayinIlkeleri-Left-text">
              1/1-Kıbrıslı Gazetesi Yayın ilkeleri , gazeteyi, ekleri, internet
              sitesini ve diğer Cumhuriyet yayınlarını kapsar ve bağlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/2-Kıbrıslı gazetesi ve diğer yayınların yöneticileri ve
              çalışanları her türden çıkar ve nüfuz ilişkilerinin dışında
              kalmaya özel bir özen gösterirler.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/3-Kıbrıslı gazetesi çalışanları bir siyasi partiye üye iseler,
              gazetedeki faaliyetleri ile siyasi çalışmalarını ayrı tutmaya özen
              gösterirler. Siyasi parti üyesi yazarlar yazılarını üyesi
              bulundukları siyasi parti içindeki konumları ya da faaliyetleri
              için kullanamazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/4-Kıbrıslı yayınlarında Related, sponsorluk vb. alanlardaki
              çalışmalarla editoryal alandaki çalışmalar kesin çizgilerle
              birbirinden ayrılır. Haberin ana ya da vazgeçilmez unsuru
              olmadıkça şirketler, ticari ürünler ve markalar yer alamaz, gizli
              Related yapılamaz.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/5-Kıbrıslı gazetesi ve yayınlarında çalışanlar haber amaçlı da
              olsa belge fotoğraf elde etmek için kimseye para teklif edemez ve
              veremez.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/6-Kıbrıslı gazetesi çalışanları kural olarak hediye kabul
              etmezler. Tanıtım amaçlı özel anlam taşımayan ya da bayram,
              yılbaşı, vb. özel günlerde geleneksel olarak verilen armağanların
              değeri kabul edilebilecek ölçüleri aşamaz.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/7-Kıbrıslı gazetesi ve diğer yayınlarda çalışanlar medya
              davetleri konusunda titiz davranırlar. Muhabirler ve editörler
              katıldıkları gezilerle ilgili yaptıkları haberlerde davet edenlere
              özel bir ayrıcalık gösteremez, övgü amaçlı yayın yapmazlar. Gazete
              ve diğer yayınlarda yönetici konumlarda olanlar konferanslara,
              bilimsel toplantılara çağrılı olmak dışında bu türden ticari
              amaçlı gezilere katılamazlar. Temsil amacıyla katıldıkları
              gezilerin masrafları gazete tarafından ödenir. Üst düzey siyasi
              gezilerde tarafsızlık ve haberde gerçekçilik ilkesinin
              zedelenmesine izin verilmez.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/8-Kıbrıslı gazetesi ve diğer yayınların yöneticileri başka yayın
              organları ve kuruluşlarla ortak çalışmalarda ve işbirliklerinde
              yayın ilkelerine aykırı davranamazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/9-Televizyon, radyo programlarına konuşmacı olarak çağrılan
              sosyal medyayı kullanan Kıbrıslı çalışanları, yazarları kurumsal
              kimliği zedeleyici üslupla konuşmaz, yazmazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/10-Kıbrıslı gazetesi ve yayınlarında çalışanlar bir ticari
              kuruluşun danışma ya da yönetim organlarında görev alamazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              1/11-Kıbrıslı gazetesi ve yayınları Vakıf Yönetim Kurulu Başkanı,
              Genel Yayın Yönetmeni, İcra Kurulu Başkanı ya da onların
              görevlendireceği kişiler tarafından temsil edilir. Bunun dışında
              hiç kimse Kıbrıslı gazetesinin adını kullanarak faaliyette ve
              temsil iddiasında bulunamaz. Kıbrıslı markası Vakıf Yönetim
              Kurulunun izni dışında herhangi bir ticari faaliyet için
              kullanılamaz.
            </p>
          </div>

          <div className="yayinIlkeleri-Left-h1">2-Yayın ilkeleri</div>

          <div className="yayinIlkeleri-Left-content">
            <p className="yayinIlkeleri-Left-text">
              2/1-Kıbrıslı gazetesi ve diğer yayınlarda temel amaç halkın haber
              alma hak ve özgürlüğünü sonuna kadar kullanabilmesinin sağlanması,
              bunun için gerekli özenin ve çabanın gösterilmesidir. Çabamız,
              adil ve açık fikirli olmak, çatışan değişik görüşleri irdeleyerek
              belli başlı bakış açılarına yer vermektir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/2-Bu amacı gerçekleştirmek için bağımsız, özgür ve nesnel
              yayıncılığın tüm kurallarına uymak zorunludur. Gazete, haberde
              gerçekliği, güncelliği, kamu yararını,nesnelliği,dengeli ve adil
              olmayı, yorumda ifade ve eleştiri özgürlüğünü esas alır. Bu ilke
              yayıncılığımızda temel aldığımız insan ve yurttaş haklarının, kamu
              yararı ilkesinin de özünü oluşturur.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/3-Kamu yararından, bir suçun açığa çıkartılması, yolsuzluk ve
              adaletsizliklerin aydınlatılması, kişi yaşamını etkileyen ciddi
              yetersizlik ve ihmallerin duyurulması, halk sağlığı ve
              güvenliğinin korunması, halkın yanıltılmasının önlenmesi ve
              insanların bilinçli karar vermesinin sağlanması hallerinde söz
              edilebilir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/4-Kıbrıslı gazetesi ve yayınlarında gerçeklerin
              saptırılmamasına, abartılmamasına, sansürlenmemesine baskı ve
              çıkar gruplarının etkisi altında kalınmamasına özen gösterilir. Bu
              kapsamda gittikçe hızlanan medya dünyasının gerisinde kalmamak
              gibi bir gerekçe hata ve yanlışların haklı görülmesine yol açmaz.
              Hata ve yanlışlar hızla ve dürüst bir şekilde düzeltilir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/5-Haberlerde gerçeğe uygunluk esastır. Haberlerde gerçeğin tüm
              boyutlarına, karşıt görüş ve iddialara yer vermek zorunluluktur.
              İddiaların muhatabı olan kişi ve kurumlardan bilgi ve yanıt almak,
              alınamıyorsa bunu haberde belirtmek gereklidir. Farklı görüş ve
              değerlendirmeleri bilmenin temel bir okur hakkı olduğu
              unutulmamalıdır.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/6-Kıbrıslı çalışanları hazırladıkları haber, röportaj, söyleşi,
              yorum ya da görüntü konusunda kendi üstleri dışında, haber kaynağı
              da dahil hiç kimseye bilgi vermek ya da onay almakla yükümlü
              değildirler.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/7-Hızla değişen toplumsal siyasal ortamın yayınlarda bire bir
              karşılığını bulması, olup bitenin tüm renkleriyle ve boyutlarıyla
              okura izleyene dinleyene aktarılmasından vazgeçilemez. Kıbrıslı
              Vakfı resmi senedinde belirtilen temel ilkeler ve bakış açısı,
              savunulması gereken temel değerler bir yana bırakılmaksızın
              gerçeğin tüm çıplaklığıyla aktarılması bir gazetecilik sorumluluğu
              ve görevidir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/8-Kıbrıslı yayınlarında, düşünce özgürlüğü eylem ve örgütlenme
              hakkı olarak anlaşılır. Yayınlarda bu özgürlüğü kısıtlayan, temel
              insan haklarına aykırı, şiddeti, kin ve düşmanlıkları kışkırtıcı,
              insanlar, topluluklar, cinsel tercihleri farklı olanlar, etnik ve
              dinsel gruplar, uluslar ve halklar arasında nefret ve düşmanlığı
              körükleyici ögeler yer almaz.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/9-Haber ve yorumlarda somut belge ve kanıtlanabilir bilgi
              olmadıkça hiç kimse peşinen sorumlu ilan edilemez, okurun doğru ve
              eksiksiz bilgilendirilmesi esas alınır. Resmi makamların üslubu ve
              dili yerine gerçeği anlatacak bir üslup ve dil kullanılır. Süren
              idari ve adli soruşturmalar ile davalarda iddialar ve savunmalar
              adil ve dengeli bir biçimde yayınlanır. Yayınlarda masumiyet
              karinesine özen gösterilir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/10-Haber ve yorumlarda insan yaşamının çeşitliliğini aktarmaktan
              geri durulmaz. Bu kapsamda yadırgatıcı yayın yapmaktan kaçınmaya
              özellikle çocukları dikkate almaya ve korumaya özen göstermek
              gerekli ve zorunludur. Haberlerde haberin unsuru olmadığı sürece
              insanlar, ırkı, milleti, etnik kökeni, sınıfı, dili, dini ya da
              mezhebi, inanç ya da inançsızlığı, meslek grubu fiziki ya da zihni
              engelli oluşu ile tanımlanamazlar, insanlar hiç bir şekilde bu
              özellikleriyle küçümsenemez, alay konusu yapılamazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/11-Haber ve yorumlarda şiddet propagandası olarak
              anlaşılabilecek türden yazı ve fotoğraflara yer verilemez.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/12-Haber ve yorumlarda kişilik haklarının ve kişilerin özel
              yaşamlarının korunması esastır. İnsanların yaşam tarzları tavır ve
              davranışları kınanamaz, onların bu özel alanları yayın konusu
              yapılamaz. Bu toplumda tanınmış kişiler örneğin sanatçılar ve
              siyasetçiler için de geçerlidir. Ancak kamu yararı söz konusu
              olduğunda haberin verilmesinin gerektirdiği ölçüyle sınırlı olarak
              bu alana girilebilir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/13-Özel yaşamın dokunulmazlığı ilkesini ihlal eden yasal ya da
              yasa dışı elde edilmiş görüntü ve ses kayıtları kamu yararı
              olmayan durumlarda kesinlikle yayımlanamaz.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/14-Gizli kamera ya da ses alma cihazı kullanılarak,
              bilgisayarlara e- postalara ve özel mülklere izinsiz girilerek
              haber yapılmaz. Haber kaynağı ya da haberde adı geçen ya da
              geçecek olanlarla yapılacak görüşmelerde konuşmanın kayıt altına
              alınacağı önceden bildirilir ve onay alınır.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/15-Kıbrıslı gazetesi ve yayınlarında çalışan gazeteciler
              kendilerine yayımlanmaması koşuluyla yapılan açıklamaları,
              sohbetleri ve verilen bilgileri hiç bir şekilde yayımlayamazlar.
              Zaman kısıtlamalarına anlaşılmış yayın saat ve günlerine uyarlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/16-Haber kaynaklarının gizliliği esastır. Siyasi otoriteler ve
              güvenlik birimleri talep etse bile kaynağın korunmasından
              vazgeçilemez. Haber kaynağı ancak kaynağın kendisinin onay verdiği
              koşullarda açıklanabilir.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/17-Kıbrıslı gazetesi ve diğer yayınlarda açıklık, şeffaflık
              esastır. Gazete ve diğer yayınların ilkelere uygun olarak çalışıp
              çalışmadığı, haberciliğin temel unsurlarına uyulup uyulmadığını
              denetleyebilmek ve okurlara, kamu oyuna hesap verebilmek için
              Vakıf Yönetim Kurulu'nca Ombudsmanlık oluşturulmuştur. Ombudsman
              ya da Okur Temsilcisi tüm yapılmış yayınları ilkelere uygunluk
              açısından gözden geçirmek hak, yetki ve sorumluluğu altında görev
              yapar. İlkeler dışında editoryal servislerden ve idareden bağımsız
              olarak görevini yerine getirir. Temsilci, okur eleştiri istek ve
              önerilerini gazetenin editoryal ve idari yönetimine iletmek,
              gerekli durumlarda habere konu olanlarla haberciler arasında
              hakemlik yapmakla yükümlüdür.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/18-Kıbrıslı çalışanları elde ettikleri bilgi ve belgeleri
              öncelikle gazete ve yayınlar için kullanırlar. Çalışanlar sosyal
              medyanın farklı alanlarında Related propaganda faaliyetleri
              yapamazlar. Bu alanlarda gazete ve gazetecilik kimliğini
              zedeleyici beyanlarda bulunamaz, yakışıksız ve kaba üslup
              kullanamazlar
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/19-Kıbrıslı gazetesi ve yayınlarında çalışan gazeteciler
              gazetenin genel yayın ilkelerine uymayı kabul ederler. Bu temel
              çizgi dışındaki telkin, öneri, istek ve talimatları reddetme
              hakkına sahiptirler. Kıbrıslı gazetesi çalışanları gazeteleri
              dışında kimseden işleriyle ilgili talimat almazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/20-Kıbrıslı gazetesi ve yayınlarında çalışanlar devlet ve
              hükümet yöneticilerinin, siyasi partilerin politikalarına, ulusal
              ve uluslararası politikalar alanında ön yargılara, resmi görüşlere
              değil halkın gerçekleri öğrenme hakkına dayanarak yayın yaparlar.
              Kıbrıslı mensupları inanmadıkları bir görüşü savunmaya ve meslek
              ilkelerine aykırı iş yapmaya zorlanamazlar.
            </p>
            <p className="yayinIlkeleri-Left-text">
              2/21-Kıbrıs Gazeteciler Cemiyeti'nin Kıbrıs Gazetecileri Hak ve
              Sorumluluk Bildirgesi Kıbrıslı Yayın İlkeleri'nin tamamlayıcı
              belgesi olarak kabul edilir.
            </p>
          </div>
          <SingleRelatedComponent />
        </div>
        <div className="aydinlatma-side">
          <Side />
        </div>
      </div>
    </>
  )
}

export default YayinIlkeleri
