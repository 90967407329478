import React, {useContext} from "react"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import RelatedComponent from "../../Components/RelatedComponent/RelatedComponent"
import {RelatedContext} from "../../Context/RelatedContext"
import "./Ziyaretci_aydinlatma.css"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
export default function Ziyaretci_aydinlatma() {
  const {Related} = useContext(RelatedContext)
  return (
    <>
      <TopRelatedCategory />
      <div className="ziyaretci-aydinlatma-body">
        <div className="ziyaretci-aydinlatma-container">
          <div className="ziyaretci-aydinlatma-content">
            <TitleBox title={"Ziyaretçi Aydınlatma Metni"} />
            <div className="ziyaretci-aydinlatma-left-line" />
            <h1 className="ziyaretci-aydinlatma-Title">
              Kıbrıslı Haber Ajansı A.Ş. Ziyaretçi Aydınlatma Metni{" "}
            </h1>
            <div>
              <h3 className="ziyaretci-aydinlatma-titleh3">
                Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma
                Metni
              </h3>
              <p>
                İşbu Kişisel Verilerin Korunması ve İşlenmesi Hakkında
                Aydınlatma Metni, KIBRISLI GAZETESİ A.Ş. (“KIBRISLI GAZETESİ”)
                olarak içerik sağlayıcısı ve veri sorumlusu olduğumuz
                <a
                  href="https://www.kibrisligazetesi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ziyaretci-aydinlatma-link"
                >
                  {" "}
                  www.kibrisligazetesi.com{" "}
                </a>
                ve
                <a
                  href="https://www.kibrisligazetesi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ziyaretci-aydinlatma-link"
                >
                  {" "}
                  www.kibrisligazetesi.com
                </a>
                uzantılı internet sitelerimizin (“İnternet Sitelerimiz”)
                kullanımı sırasında elde edilen ve/veya mezkur internet
                sitelerine üye olan (“Üyelerimiz”) veya ziyaret eden
                (“Ziyaretçilerimiz”) kişilerden alınan kişisel verilerin
                kullanımına ilişkin olarak 6698 sayılı Kişisel Verilerin
                Korunması Hakkında Kanun’un 10. maddesinde düzenlenen aydınlatma
                yükümlülüğümüzün yerine getirilmesidir.
                Üyelerimizin/Ziyaretçilerimizin İnternet Sitelerimizin kullanımı
                ile ilgili olarak KIBRISLI GAZETESİ tarafından toplanan veya
                kendilerinin İnternet Sitelerimize girdikleri kişisel verilerin
                toplanma şekilleri, işlenme amaçları, hukuki nedenleri ve
                hakları konularında şeffaf bir şekilde
                Üyelerimizi/Ziyaretçilerimizi bilgilendirmek amacıyla işbu metin
                hazırlanmıştır.
              </p>
              <p>
                <strong>1.</strong> Veri Sorumlusu
              </p>
              <p>
                Kişisel Verilerin Korunması Kanunu uyarınca, kişisel
                verileriniz; veri sorumlusu olarak KIBRISLI GAZETESİ A.Ş.
                tarafından aşağıda açıklanan kapsamda toplanacak ve
                işlenebilecektir.
              </p>

              <p>
                <strong>2.</strong> Toplanan Kişisel Veriler
              </p>
              <p>
                Aşağıda belirtilen yöntemlerle Üyelerimizden/Ziyaretçilerimizden
                çeşitli statik (sabit) ve dinamik (değişken) veriler
                toplanmaktadır. Toplanılan veriler,
                Üyelerimizin/Ziyaretçilerimizin kullandığı hizmetlere ve
                özelliklere göre değişkenlik göstermektedir.
              </p>
              <p>
                İşbu başlık altında, İnternet Sitelerimiz aracılığı ile
                sunduğumuz hizmetler kapsamında işlenen ve Kişisel Verilerin
                Korunması Kanunu uyarınca kişisel veri sayılan verilerin
                hangileri olduğu aşağıda gruplar halinde sıralanmıştır.
              </p>

              <p>
                <strong>-</strong> Kayıt/Üyelik/Kullanım Sırasında Paylaştığınız
                Bilgiler: İnternet Sitelerimizi kullanmak veya bunlara kayıt
                veya üye olmak için sağlamış olduğunuz (örneğin: bir hesap
                oluşturmak, yorum göndermek, haber bülteni almak veya bir
                yarışmaya veya çekilişe katılmak için) bilgilerdir. Kayıt
                Bilgileri, sayılanlarla sınırlı olmamak üzere; isim, soy isim,
                elektronik posta adresi, cinsiyet, posta kodu, doğum günü, cep
                ve ev telefonu numarası, medeni hal, adres, eğitim durumu,
                meslek gibi bilgileri içerebilir.
              </p>

              <p>
                <strong>-</strong> Mobil uygulamalar aracılığıyla alınan
                bilgiler: Mobil uygulamalarımızı kullanırken bizimle
                paylaştığınız bilgiler; konum bilgisi, medya dosyaları,
                profiller, kişiler, depolama, kablosuz ve bluetooth bağlantı
                detayları gibi bilgileri içerebilir.
              </p>

              <p>
                <strong>-</strong> Anketler aracılığıyla alınan
                bilgiler: İnternet Sitelerimizde yer alan anketlere verdiğiniz
                yanıtlardır.
              </p>

              <p>
                <strong>-</strong> Kamuya açık bilgi ve
                gönderiler: Gönderdiğiniz yorum ve içerik ile isim, kullanıcı
                adı, yorumlar, beğeniler, statü, profil bilgileri ve fotoğraf da
                dahil bu gönderi ve içerikle beraber bulunabilecek sizin
                hakkınızdaki bilgilerden oluşur. Gönderiler her zaman kamuya
                açıktır. Başka bir deyişle bu bilgilere herkes ulaşabilir ve
                harici arama motorlarının arama sonuçlarında görüntülenebilir.
              </p>
              <p>
                <strong>-</strong>Sosyal medyadan alınan bilgiler: Bir sosyal
                medya hizmeti üzerinden İnternet Sitelerimize erişir veya giriş
                yaparsanız veya İnternet Sitelerimizde yer alan bir hizmeti bir
                sosyal medya hesabına bağlarsanız, topladığımız bilgiler
                arasında kullanıcı kimliğiniz ve/veya bu sosyal medya hizmeti
                ile ilgili kullanıcı adınız, profil resminiz, elektronik posta
                adresiniz veya arkadaş listeniz ve bu sosyal medya hizmeti ile
                bağlantılı olarak kamuya açık hale getirdiğiniz bilgiler gibi
                sosyal medya hizmetinin bizimle paylaşmasına izin verdiğiniz her
                türlü bilgi ve içerik de yer alabilir. Sosyal medya hizmetleri
                üzerinden hizmetlerimize eriştiğinizde veya İnternet
                Sitelerimizdeki bir hizmeti sosyal medya hizmetlerine
                bağladığınızda, KIBRISLI GAZETESİ’ü işbu Aydınlatma Metni’ne
                uygun bir şekilde bu tür bilgi ve içerikleri toplama, saklama ve
                kullanma konusunda yetkilendirmiş olursunuz.
              </p>
              <p>
                <strong>-</strong> Faaliyet bilgileri: İnternet Sitelerimize
                eriştiğinizde ve bu hizmetleri kullandığınızda, bu
                ziyaretleriniz ile ilgili bazı bilgileri toplayabiliriz.
                Örneğin, İnternet Sitelerimize bağlanabilmenizi sağlamak için
                sunucularınız, IP adresiniz, tarayıcı türünüz ve diğer yazılım
                veya donanım bilgileriniz de dahil, bilgisayar, cihaz ve
                tarayıcınız hakkında bilgileri alıp kaydedebiliriz.
              </p>

              <p>
                <strong>-</strong>Otomatik olarak toplanan bilgiler: Çerezler ve
                diğer izleme teknolojileri (beacon, tarayıcı çerezleri,
                pikseller, işaretçiler ve sair çerezler) üzerinden size ait
                verileri toplayabiliriz. Çerezlere ilişkin daha detaylı bilgi
                için lütfen Çerez Politikamızı inceleyiniz.
              </p>
              <p>
                <strong>3.</strong>Kişisel Verilerin Hangi Amaçla İşleneceği
              </p>
              <p>
                İşbu Aydınlatma Metni’nde yer alan amaçlar doğrultusunda kişisel
                verileriniz, 6698 Sayılı Kişisel Verilerin Korunması Kanunu ile
                düzenlenen ilkeler ve işleme şartları uyarınca ve aşağıda
                detayları belirtilen amaçlar dairesinde işlenecektir:
              </p>
              <p>
                Yayıncılık faaliyetlerimizin yürütülmesi,
                Üyelerimize/Ziyaretçilerimize destek hizmeti sağlanması, tercih
                ve ihtiyaçlarının tespit edilerek verilecek hizmetlerin bu
                kapsamda şekillendirilmesi ve güncellenmesi, şirketimiz
                tarafından yürütülen faaliyetlerin gerçekleştirilmesi için
                ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve
                buna bağlı iş süreçlerinin yürütülmesi, yasal düzenlemelerin
                gerektirdiği veya zorunlu kıldığı şekilde hukuki
                yükümlülüklerimizin yerine getirilmesi, internet sitelerimize,
                mobil uygulamalarımıza ve mobil web sitelerimize iletilen üyelik
                taleplerinin değerlendirilmesi ve üyelik ilişkisinin kurulması,
                elektronik posta ile bülten gönderilmesi ve bildirim yapılması,
                pazar araştırmaları ve istatistiki çalışmaların yapılması,
                şirket içerisindeki talimat ve prosedürlerin uygulanması,
                etkinlik ve organizasyonların düzenlenmesi, okuyucu, dinleyici
                ve izleyici memnuniyetinin ölçülmesi, Related, tanıtım ve
                pazarlama faaliyetlerinin planlanması ve yürütülmesi, Related
                satışlarının gerçekleştirilmesi, risk yönetimi ve kalite
                geliştirme çalışmalarının planlanması ve yerine getirilmesi,
                hizmet kalitesinin ölçülmesi, optimizasyon, denetim, istatistikî
                analiz, risk yönetimi, üye doğrulama işlemlerinin yapılması,
                konuma bağlı veya konumla ilişkili İnternet Sitelerimize ait
                fonksiyonların kullandırılması, profilleme, işbirliği yapılan
                üçüncü gerçek ve tüzel kişiler tarafından Üye/Ziyaretçilere
                pazarlama yapılması amaçlarıyla ve KVKK’nın 5. ve 6.
                maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                dahilinde işlenecektir.
              </p>
              <p>
                <strong>4.</strong> İşlenen Kişisel Verilerin Kimlere ve Hangi
                Amaçla Aktarılabileceği
              </p>
              <p>
                Üyelerimize/Ziyaretçilerimize ait veriler; iş ortaklığı
                kapsamında planlanan faaliyetlerin gerçekleştirilmesi amacıyla
                iş ortaklarımıza, tedarikçilerimizden temin edilecek hizmetin
                yerine getirilmesi amacıyla tedarikçilerimize, Grup Şirketleri
                ile mevcut olan ilişkiler kapsamında planlanan faaliyetlerin
                gerçekleştirilmesi amacıyla Grup Şirketlerimize ve yasal
                düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki
                yükümlülüklerimizin yerine getirilmesi amacıyla Hukuken Yetkili
                Kamu Kurum ve Kuruluşları ile Hukuken Yetkili Özel Hukuk
                Kişilerine aktarılabilecektir.
              </p>
              <p>
                Doğrudan KIBRISLI GAZETESİ’e Vermiş Olduğunuz Veriler: İnternet
                Sitelerimizin kullanımı öncesinde veya sırasında,
                Üyelerimizin/Ziyaretçilerimizin kendi inisiyatifleri
                doğrultusunda verdikleri kişisel verileri ifade eder. Bu kişisel
                veriler, doğrudan Üyelerimiz/Ziyaretçilerimiz tarafından
                KIBRISLI GAZETESİ’e pozitif hareketleri neticesinde verilen tüm
                kişisel verileri kapsar.
              </p>
              <p>
                İnternet Sitelerimize üye olurken ve bültenlerimize abone
                olurken paylaştığınız bilgiler, anketlere verdiğiniz yanıtlar,
                iletişim/şikayet formları ile yaptığınız başvurular, KIBRISLI
                GAZETESİ’e gönderdiğiniz e-postalar bu kapsamdadır. Örneğin;
                ad-soyad, iletişim bilgileri, kimlik bilgileri, anketlere
                verilen cevaplar, demografik veriler ve içerik bilgileri bu tür
                verilere girmektedir.
              </p>
              <p>
                Platformumuzu Kullandığınız Zaman Elde Ettiğimiz
                Veriler: KIBRISLI GAZETESİ’ün sunduğu hizmet sırasında, belirli
                yazılım ya da teknolojik araçlar vasıtasıyla alınan
                Üyelerimizin/Kullanıcılarımızın kullanım alışkanlıklarına
                ilişkin kişisel verileri kapsamaktadır. Örneğin, konum verileri
                ve sık kullanılanlar ile birlikte ilgi alanları ve kullanım
                verileri bu tür verilere girmektedir.
              </p>

              <p>
                KIBRISLI GAZETESİ, online davranışsal Relatedcılık ve pazarlama
                yapılabilmesi amacıyla İnternet Sitelerimizi ziyaret eden
                Ziyaretçilerimizin, üye olmasalar dahi sitedeki davranışlarını
                tarayıcıda bulunan bir cookie (çerez) ile ilişkilendirme ve
                görüntülenen sayfa sayısı, ziyaret süresi ve hedef tamamlama
                sayısı gibi metrikleri temel alan yeniden pazarlama listeleri
                tanımlama işlemleri yapabilir. Çerezlere ilişkin daha detaylı
                bilgi için lütfen Çerez Politikamızı inceleyiniz.
              </p>

              <p>
                Bu yöntemlerle toplanan kişisel verileriniz 6698 sayılı Kanun’un
                5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve
                amaçları kapsamında bu Aydınlatma Metninde belirtilen amaçlarla
                işlenebilmekte ve aktarılabilmektedir.
              </p>
              <p>
                Kişisel verilerinizin işlenmesinin hukuki dayanakları: Kişisel
                verileriniz; bir hakkın tesisi, kullanılması veya korunması için
                verilerinizin işlenmesinin zorunlu olduğu, fiili imkânsızlık
                nedeniyle rızanızın alınamadığı, bir sözleşmenin kurulması veya
                ifasıyla doğrudan doğruya ilgili olarak kişisel verilerinizin
                işlenmesinin gerekli olduğu, hukuki yükümlülüklerimizin yerine
                getirebilmesi için zorunlu olduğu, kişisel verilerinizin
                tarafınızca alenileştirildiği, meşru menfaatlerimiz için
                verilerinizin işlenmesinin zorunlu olduğu ve kanunlarda açıkça
                öngörülen hallerde ve şayet söz konusu durumlardan biri mevcut
                değil ise bu durumda açık rızanızı almak koşuluyla
                işlenebilmekte ve aktarılabilmektedir.
              </p>

              <p>
                <strong>6.</strong> Kişisel Veri Sahibi olarak 6698 sayılı
                Kanun’un 11. maddesinde Sayılan Haklarınız
              </p>
              <p>Kişisel Veri Sahibi kişisel verileri üzerinde;</p>
              <p>
                <strong>a)</strong> Kişisel verilerinin işlenip işlenmediğini
                öğrenme,
              </p>
              <p>
                <strong>b)</strong>Kişisel verileri işlenmişse buna ilişkin
                bilgi talep etme,
              </p>
              <p>
                <strong>c)</strong> Kişisel verilerinin işlenme amacını ve
                bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
              </p>
              <p>
                <strong>d)</strong>Yurt içinde veya yurt dışında kişisel
                verilerinin aktarıldığı üçüncü kişileri bilme,
              </p>
              <p>
                <strong>e)</strong> Kişisel verilerinin eksik veya yanlış
                işlenmiş olması halinde bunların düzeltilmesini isteme,
              </p>
              <p>
                <strong>f)</strong> Kişisel verilerinin işlenmesini gerektiren
                sebeplerin ortadan kalkması halinde kişisel verilerin
                silinmesini veya yok edilmesini isteme,
              </p>
              <p>
                <strong>g)</strong> Yukarıda bahsedilen düzeltme, silme veya yok
                etme işlemlerinin, kişisel verilerin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,
              </p>
              <p>
                <strong>h)</strong> İşlenen verilerinin münhasıran otomatik
                sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe bir sonuç
                ortaya çıkmasına itiraz etme,
              </p>
              <p>
                <strong>j)</strong> Kişisel verilerinin kanuna aykırı olarak
                işlenmesi sebebiyle zarara uğraması halinde zararın
                giderilmesini talep etme haklarına sahiptir.
              </p>
              <p>
                <strong>7.</strong> Kişisel verilere ilişkin hakların
                kullanılması:
              </p>
              <p>
                Üyelerimiz/Ziyaretçilerimiz, yukarıda belirtilen haklarına
                ilişkin taleplerini; bu hususta hazırlayacakları dilekçenin
                ıslak imzalı bir nüshasını iadeli taahhütlü posta aracılığı ile
                KIBRISLI GAZETESİ’ün “Abdülhakhamit Cad. No:25 Beyoğlu/İstanbul”
                adresine göndererek veya kimliğini ibraz etmek şartı ile ıslak
                imzalı dilekçeyi aynı adrese şahsen elden teslim ederek veya
                imzalı dilekçeyi
                <a
                  href="https://www.kibrisligazetesi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ziyaretci-aydinlatma-link"
                >
                  {" "}
                  www.kibrisligazetesi.com{" "}
                </a>{" "}
                adresine kayıtlı elektronik posta, güvenli elektronik imza,
                mobil imza ya da Şirket’e daha önce bildirmiş olduğu ve KIBRISLI
                GAZETESİ’ün sisteminde kayıtlı bulunan elektronik posta adresini
                kullanarak iletebilir. Başvuru zarfının üzerine veya elektronik
                iletinin konu kısmına “Kişisel Verilere İlişkin Bilgi Talebi”
                yazılması gerekmektedir. Üyelerimiz/Ziyaretçilerimiz tarafından
                yukarıda belirtilen hakların kullanımı için yapılacak başvuruda;
                talep edilen hususun açık ve anlaşılır olması, talep edilen
                konunun başvuranın şahsı ile ilgili olması veya başkası adına
                hareket ediliyor ise bu konuda özel olarak yetkili olması ve bu
                yetkinin belgelendirilmesi, başvuruya kimliği tevsik edici
                belgelerin (T.C. Kimlik kartı fotokopisi vb.) eklenmesi
                gerekmektedir. Söz konusu talepler bireysel olarak yapılacak
                olup, yetkisiz üçüncü kişilerin kişisel veriler ile ilgili
                yaptığı talepler değerlendirmeye alınmayacaktır.
              </p>
              <p>
                Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ
                uyarınca başvuruda ilgili kişinin;
              </p>
              <p>
                <strong>a)</strong>Adı, soyadı, imzası,
              </p>
              <p>
                <strong>b)</strong> Türkiye Cumhuriyeti vatandaşları için T.C.
                kimlik numarası, yabancılar için uyruğu, pasaport numarası veya
                yabancı kimlik numarası,
              </p>
              <p>
                <strong>c)</strong>Tebligata esas yerleşim yeri veya iş yeri
                adresi,
              </p>
              <p>
                <strong>d)</strong> Varsa bildirime esas elektronik posta
                adresi, telefon ve faks numarası,
              </p>
              <p>
                <strong>e)</strong> Talep konusu,
              </p>
              <p>
                bilgilerinin belirtilmesi zorunludur. Aksi halde başvuru geçerli
                bir başvuru olarak değerlendirilmeyecektir. Ayrıca, konuya
                ilişkin bilgi ve belgeler başvuruya eklenmelidir.
              </p>
              <p>
                KIBRISLI GAZETESİ’ün cevap vermeden önce kimliğinizi doğrulama
                hakkı saklıdır.
              </p>
              <p>
                Kişisel veri sahibi olarak, haklarınıza ilişkin taleplerinizi
                yukarıda belirtilen şekilde KIBRISLI GAZETESİ’e iletmeniz
                durumunda, bu talebiniz, KIBRISLI GAZETESİ’e tebliğ edildiği
                tarihten itibaren en geç otuz gün içinde sonuçlandırılacaktır.
              </p>
            </div>
            <SingleRelatedComponent />
          </div>
          <div className="ziyaretci-aydinlatma-side">
            <Side />
          </div>
        </div>
      </div>
    </>
  )
}
