import React from "react";
import Side from "../../Components/Navbar/PlayersList/Side/Side";
import TitleBox from "../../Components/TitleBox/TitleBox";
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory";
import "./Reklam.css";
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent";
import RelatedImage from "../../img/kibrisli-logo.jpg";
import anasayfa from "../../img/relatedAlanları/anasayfa.png";
import popup from "../../img/relatedAlanları/popup.png";
import shortsRelated from "../../img/relatedAlanları/shortsreklam1.png";
import Manset from "../../img/relatedAlanları/manset.png";
import Cerez from "../../img/relatedAlanları/cerez.png";

const Reklam = () => {
  return (
    <>
      <TopRelatedCategory />
      <div className="Related-Left">
        <div className="Related-Left-ad">
          <TitleBox title="Reklam" />
          <div className="Related-header">
            <img src={RelatedImage} alt="Related Görseli" className="Related-header-kıbrıslı-logo"/>
            <div className="Related-header-info">
              <p>
                Web sitemizde farklı alanlarda çeşitli boyutlarda Related
                alanları bulunmaktadır. Bu alanlara haber vermek için bizimle
                iletişime geçebilirsiniz.
              </p>
              <p>Kıbrıslı Gazetesi Reklam: <a style={{textDecoration:"none",color:"black"}} href="mailto:reklam@kibrisligazetesi.com">reklam@kibrisligazetesi.com</a></p>

              <p>Haber Hattı: +90 533 888 11 37</p>
              <div className="Related-header-info-more">
                <h2>Reklam alanlarımız</h2>
              </div>
            </div>
            <div className="ana-sayfa-Related">
              <img src={anasayfa} alt="Related Görseli" />
            </div>{" "}
            <div className="ana-sayfa-Related">
              <img src={popup} alt="Related Görseli" />
            </div>
            <div className="ana-sayfa-Related">
              <img src={shortsRelated} alt="Related Görseli" />
            </div>
            <div className="ana-sayfa-Related">
              <img src={Manset} alt="Related Görseli" />
            </div>
            <div className="ana-sayfa-Related">
              <img src={Cerez} alt="Related Görseli" />
            </div>
          </div>
        </div>
        <div className="Related-side">
          <Side />
        </div>
      </div>
    </>
  );
};

export default Reklam;
