import React from "react"
import "./OzelHaberImage.css" // CSS dosyasını import ediyoruz

export default function OzelHaberImage({ReporterImg, categorySlider}) {
  if (!ReporterImg) return null

  return (
    <div className="ozel-haber-container">
      <img
        src={ReporterImg}
        alt="Muhabir"
        className={categorySlider ? "reporter-img large" : "reporter-img small"}
      />
    </div>
  )
}
