import React, { createContext, useState, useEffect } from "react";
import { Config } from "../Config/config";
import SideDefaultImg from "../img/435x470.jpg"

export const RelatedContext = createContext();

export const RelatedProvider = ({ children }) => {
  const [Related, setRelated] = useState([]);

  useEffect(() => {
    const fetchRelated = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/unblockeddata/?is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Relatedlar alınamadı");
        }
        const data = await response.json();
        setRelated(() => {
          let exist = false;
          data.forEach((item) => {
            if (item.area__id === 1) {
              exist = true;
            }
          });
          if (!exist) {
            data.push({
              id: 5,
              advert_area: 1,
              area__id: 1,
              advert_area__name: "Pop-up reklam",
              header: "Kıbrıslı Gazetesi Pop-up Reklam alanı",
              image: SideDefaultImg,
              redirect_url: "/reklam",
            });
          }
          return data;
        });
      } catch (error) {
        console.error("Relatedlar yüklenirken bir hata oluştu", error);
      }
    };

    fetchRelated();
  }, []);

  return (
    <RelatedContext.Provider value={{ Related }}>
      {children}
    </RelatedContext.Provider>
  );
};
