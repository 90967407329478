import {useEffect} from "react"
import {useLocation} from "react-router-dom"

const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to the top of the page when the route changes
  }, [location]) // Only re-run this effect when the location changes

  return null
}

export default ScrollToTop
