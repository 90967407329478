import React, {useState, useEffect, useContext} from "react"
import {Config} from "../../Config/config"
import "./HaberDetails.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import {useParams, useLocation} from "react-router-dom"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import Socialmedia from "./Socialmedia/Socialmedia"
import {Link} from "react-router-dom"
import {IoPersonCircleOutline} from "react-icons/io5"
import {IoMdMail} from "react-icons/io"
import logo from "../../img/kibrisli-logo.jpg"
import {RelatedContext} from "../../Context/RelatedContext"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
import HaberDefaultImage from "../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import HaberIciRelatedUst from "../../Components/RelatedComponent/HaberIciRelatedUst"
import HaberIciRelatedOrta from "../../Components/RelatedComponent/HaberIciRelatedOrta"
import RelatedComponent from "../../Components/RelatedComponent/RelatedComponent"
import OzelHaberEtiketi from "../../Components/OzelHaberEtiketi/OzelHaberEtiketi"
import TimeAgo from "../../Components/TimeAgo"
import Loading from "../../Components/Loading/Loading"
import {turkceToEnglish} from "../../Components/PathHelper/PathHelper"

export default function HaberDetails() {
  const [news, setNews] = useState(null)
  const [editorEmail, setEditorEmail] = useState("haber@kibrisligazetesi.com")
  const [coverImage, setCoverImage] = useState(null)
  const [loading, setLoading] = useState(true)
  const {id} = useParams()
  const {Related} = useContext(RelatedContext)
  const location = useLocation()

  useEffect(() => {
    const handlePreviewData = async (previewData) => {
      if (!previewData || typeof previewData !== "string") {
        console.error("Invalid preview data type:", typeof previewData)
        setNews(null)
        return
      }

      try {
        const decodedData = decodeURIComponent(previewData)
        const incomingData = JSON.parse(decodedData)
        setNews(incomingData)
      } catch (error) {
        console.error("Preview data parsing error:", error)
        setNews(null)
      }
    }

    const fetchNewsData = async (newsId) => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/post/?id=${newsId}&is_published=true`,
          {
            method: "GET",
            headers: {Authorization: `Token ${Config.Token}`},
          }
        )

        if (!response.ok) {
          throw new Error("Failed to fetch news")
        }

        const data = await response.json()

        if (!data.results?.[0]) {
          throw new Error("No news data found")
        }

        setNews(data.results[0])
        //   console.log(data.results[0])
        const coverImage = data.results[0].postimage.find(
          (img) => img.is_cover_image
        )?.uploaded_image.image
        setCoverImage(coverImage)

        // Increment view count in separate try-catch to not affect main functionality
        try {
          await fetch(
            `https://api.kibrisligazetesi.com/increase-web-count/${newsId}`,
            {
              method: "GET",
              headers: {Authorization: `Token ${Config.Token}`},
            }
          )
        } catch (error) {
          console.error("Failed to increment view count:", error)
        }
      } catch (error) {
        console.error("News fetch error:", error)
        setNews(null)
      }
    }

    const loadNewsContent = async () => {
      setLoading(true)
      try {
        const previewData = location.pathname.includes("/onizleme/")
          ? location.pathname.split("/onizleme/")[1]
          : null

        if (previewData) {
          await handlePreviewData(previewData)
        } else if (id) {
          await fetchNewsData(id)
        } else {
          throw new Error("No news ID or preview data found")
        }
      } catch (error) {
        console.error("Error loading news:", error)
        setNews(null)
      } finally {
        setLoading(false)
      }
    }

    loadNewsContent()
  }, [location.pathname, id])

  return (
    <>
      <TopRelatedCategory />
      <div className="haber-details-container">
        <div className="haber-details-content">
          <HaberIciRelatedUst />

          {loading ? (
            <div className="news-loading-container">
              <Loading />
            </div>
          ) : !id && !location.pathname.includes("/onizleme/") ? (
            <div className="news-error-container">
              <p>Haber bulunamadı!</p>
            </div>
          ) : !news ? (
            <div className="news-error-container">
              <p>Haber yüklenemedi.</p>
            </div>
          ) : (
            // News content
            <>
              <h1>
                {news.header ||
                  (news.is_manset && news.mansetpost?.[0]?.manset_header)}
              </h1>
              <p className="image-caption">{news.sub_header}</p>
              <div className="haber-details-image-fyi">
                <Link to="/#">
                  {news.reporter ? (
                    <span className="haber-details-editor-name">
                      {news.reporter__name}
                    </span>
                  ) : news.editor ? (
                    news.is_koseyazisi === false ? ( //false ise editor kıbrıslıGazetesi
                      <div className="haber-details-kibrisli-news">
                        <img
                          src={logo}
                          alt="Logo"
                          className="haber-details-image-editor-logo"
                        />
                        <span className="haber-details-editor-name">
                          {news.editor__name || "Kıbrıslı Gazetesi"}
                        </span>
                      </div>
                    ) : (
                      <Link
                        to={`/yazarlar/${news.editor}/${turkceToEnglish(
                          news.editor__name
                        )}`}
                        className="haber-details-kibrisli-news"
                      >
                        {news.editor && (
                          <OzelHaberEtiketi
                            editor={news.editor}
                            haberDetail={false}
                            editorDetail
                            onUserMail={(email) => setEditorEmail(email)}
                          />
                        )}

                        <span className="haber-details-editor-name">
                          {news.editor__name}
                        </span>
                      </Link>
                    )
                  ) : null}
                </Link>

                {editorEmail && (
                  <a
                    href={`mailto:${editorEmail}`}
                    style={{textDecoration: "none"}}
                  >
                    <IoMdMail
                      style={{
                        color: "#868686",
                        width: 20,
                        height: 23,
                        marginTop: "0.2rem",
                      }}
                    />
                  </a>
                )}
                <TimeAgo createdAt={news.publish_date} />
              </div>

              <div className="haber-details-image-wrapper">
                {news.reporter && (
                  <OzelHaberEtiketi
                    reporter={news.reporter}
                    haberDetail={true}
                  />
                )}

                <img
                  src={coverImage || HaberDefaultImage}
                  alt={news.header || ""}
                  className="haber-details-container-image"
                  onError={(e) => {
                    e.target.onerror = null
                    e.target.src = "default-image.jpg"
                  }}
                />
              </div>

              <div className="social-media-icons">
                <Socialmedia
                  title={news.header}
                  description={news.sub_header}
                  content={news.content}
                  image={coverImage}
                />
              </div>
              <div className="line"></div>
              <HaberIciRelatedOrta />
              <div className="haberContent">
                <div dangerouslySetInnerHTML={{__html: news.content}} />
              </div>
              {news.tag && news.tag.length > 0 && (
                <div className="haber-tags">
                  <div className="tags-container">
                    {[news.tag].flat().map((tag, index) => (
                      <span key={index} className="tag">
                        #{tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="HaberIcı-Alt-Related-container">
                <RelatedComponent relatedId={4} />
                <RelatedComponent relatedId={5} />
              </div>
            </>
          )}
        </div>
        <div className="haber-details-side-wrapper">
          <Side />
        </div>
      </div>
    </>
  )
}
