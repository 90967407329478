import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import Side from "../../../Components/Navbar/PlayersList/Side/Side"
import "./YazarDetails.css"
import {Config} from "../../../Config/config"
import TopRelatedCategory from "../../../Components/RelatedComponent/TopRelatedCategory"
import HaberCardDetails from "../../../Components/HaberCardDetails/HaberCardDetails"
import Loading from "../../../Components/Loading/Loading"

const YazarDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {id} = useParams()
  const [yazar, setYazar] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchYazarDetails = async () => {
      if (!id) return

      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/reportereditor/?is_reporter=false&id=${id}&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        )
        const data = await response.json()
       // const yazarData = data.find((yazar) => yazar.id === id)
        setYazar(data?.[0])
      } catch (error) {
        console.error("Hata:", error)
        setError("Yazar bilgileri alınırken bir hata oluştu.")
      } finally {
        setLoading(false)
      }
    }

    fetchYazarDetails()
  }, [id])

  return (
    <>
      <TopRelatedCategory />
      {loading ? (
        <div className="news-loading-container">
          <Loading />
        </div>
      ) : error ? (
        <div className="news-error-container">
          <p>{error}</p>
        </div>
      ) : !yazar ? (
        <div className="news-error-container">
          <p>Yazar bulunamadı!</p>
        </div>
      ) : (
        <div className="yazarlarimiz-containerr">
          {yazar.banner && yazar.banner.length > 0 && (
            <div className="banner-container">
              <img
                className="banner-image"
                src={yazar.banner[0].image}
                alt="Yazar Banner"
              />
            </div>
          )}
          <div className="maincontent-wrapper">
            <div className="main-content">
              <div className="yazar-wrap">
                <div className="yazar-details-image-container">
                  <img
                    className="yazar-image"
                    src={yazar.image || "default-image.jpg"}
                    alt={yazar.name}
                  />
                  <h1 className="yazar-name">{yazar.name}</h1>
                </div>
                <h3 className="yazar-slug">{yazar.title}</h3>
                {yazar.email ? <p>Email: {yazar.email}</p> : ""}

                <div className="HaberCardDetails">
                  <HaberCardDetails id={yazar.id} name={yazar.name} />
                </div>
              </div>
              <div className="side-container">
                <Side />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default YazarDetails
