import React from "react"
import "./ShortHaber.css"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"

const ShortHaber = () => {
  return (
    <>
      <TopRelatedCategory />
      <div className="yayinIlkeleri-Left">
        <div className="yayinIlkeleri-Left-ad">
          <TitleBox title="Aksa Süper Lig" />
        </div>
        <div className="aydinlatma-side">
          <Side />
        </div>
      </div>
    </>
  )
}

export default ShortHaber
