import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import "./Dropdown.css";
import { Config } from "../../../../Config/config";
import Loading from "../../../Loading/Loading";
import {turkceToEnglish} from "../../../PathHelper/PathHelper"
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/category/?is_like_subcat=true&is_active=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${Config.Token}`,
            },
          }
        );
        const data = await response.json();

        if (Array.isArray(data)) {
          const categories = data.map((category) => ({
            id: category.id,
            name: category.name,
            image: category.image,
          }));
          setItems(categories);
        } else {
          console.error("Hatalı veri formatı:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dropdown-container">
      <button onClick={toggleDropdown} className="dropdown-button">
        {isOpen ? <IoMdClose size={20} /> : <GiHamburgerMenu size={20} />}
      </button>

      {isOpen && (
        <div ref={dropdownRef} className="dropdown-centered">
          <div className="dropdown-menu">
            <div className="dropdown-category-title">
              <p className="titles">Bölümler</p>
              <div className="line"></div>
            </div>
            <div className="dropdown-category-title">
              <p className="titles">Bağlantılar</p>
              <div className="line"></div>
            </div>
            <div className="dropdown-category-title">
              <p className="titles">Uygulamalar</p>
              <div className="line"></div>
            </div>
            {/*
            
                <div className="dropdown-category-title">
              <p className="titles">Galeri</p>
              <div className="line"></div>
            </div>
            */}

            <div className="dropdown-container">
              <div className="dropdown-grid">
                {items.length > 0 ? (
                  items.map((category) => (
                    <div key={category.id} className="dropdown-section">
                      <div className="dropdown-items">
                        <Link
                          to={`/${category.id}/${turkceToEnglish(category.name)}`}
                          state={{ categoryName: category.name }}
                          className="dropdown-item"
                          onClick={() => setIsOpen(false)}
                        >
                          {category.name}
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <Loading />
                )}
                {/* <button className="button-dropdown-item">
                  <Link to="/vefatlar">{"Vefatlar"}</Link>
                </button> */}
              </div>
            </div>
            <div className="dropdown-container">
              <div className="dropdown-grid">
                <div className="dropdown-section">
                  <div className="dropdown-items">
                    <div className="dropdown-item-statick">
                      <div>
                        <Link to="/iletisim" onClick={() => setIsOpen(false)}>
                          İletişim
                        </Link>
                      </div>

                      <div>
                        <Link to="/reklam">Reklam</Link>
                      </div>
                      <div>
                        <Link to="/kunye" onClick={() => setIsOpen(false)}>
                          Künye
                        </Link>
                      </div>
                      <div>
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/yayin-ilkeleri"
                        >
                          Yayın İlkeleri
                        </Link>
                      </div>
                      <div>
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/gizlilik-politikasi"
                        >
                          Gizlilik Politikası
                        </Link>
                      </div>
                      <div>
                        <Link onClick={() => setIsOpen(false)} to="/cerez">
                          Çerez
                        </Link>
                      </div>
                      <div>
                        <Link onClick={() => setIsOpen(false)} to="/aydinlatma">
                          Müşteri Aydınlatma Metni
                        </Link>
                      </div>
                      <div>
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/ziyaretci-aydinlatma"
                        >
                          Ziyaretçi Aydınlatma Metni
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown-container">
              <div className="dropdown-grid">
                <div className="dropdown-section">
                  <div className="dropdown-items">
                    <div className="dropdown-item-statick">
                      <div>
                        <Link
                          onClick={() => setIsOpen(false)}
                          to="/aksa-super-lig"
                        >
                          Aksa Süper Lig
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*
            
                   <div className="dropdown-container">
              <div className="dropdown-grid">
                <div className="dropdown-section">
                  <div className="dropdown-items">
                    <div className="dropdown-item-statick">
                      <div>
                        <Link to="/shorts">Short Haberler</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
