import React from "react"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"

const AksaSuperLig = () => {
  return (
    <>
      <TopRelatedCategory />
      <div className="yayinIlkeleri-Left">
        <div className="yayinIlkeleri-Left-ad">
          <TitleBox title="Aksa Süper Lig" />
          <iframe
            src="https://ktffapp.com/iframe.php"
            style={{border: "none", marginTop: "10px"}}
            title="Aksa Süper Lig"
            width="100%"
            height="780"
          ></iframe>
          <SingleRelatedComponent />
        </div>
        <div className="aydinlatma-side">
          <Side />
        </div>
      </div>
    </>
  )
}

export default AksaSuperLig
