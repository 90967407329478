import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import "./HaberCardDetails.css";
import Rectangle from "../../img/kıbrıslı-gazetesi-haber-default-img-min.jpg";
import { Config } from "../../Config/config";
import { useNavigate } from "react-router-dom";
import OzelHaberImage from "../OzelHaberEtiketi/OzelHaberImage";
import Loading from "../Loading/Loading";
import Pagination from "../Pagination/Pagination";
import { turkceToEnglish } from "../PathHelper/PathHelper";
const HaberCardDetails = ({ id, name }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; 
      try {
        const response = await fetch(
          `${Config.ApiBaseUrl}/postbasic/?editor=${id}&is_published=true&page=${currentPage}`,
          {
            method: "GET",
            headers: { Authorization: `Token ${Config.Token}` },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Veri çekme başarısız, Status Code: ${response.status}`
          );
        }

        const result = await response.json();
        setTotalPages(result.pages || 1);
        if (result.results && result.results.length > 0) {
          setData(result.results ||[]);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Hata:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, currentPage]);

  const handleNewsClick = (haberId,title) => {
    const SafeTitle=turkceToEnglish(title);
    navigate(`/haber/${haberId}/${SafeTitle}`);
  };

  return (
    loading ? (
      <div className="news-loading-container">
        <Loading />
      </div>
    ) : !id ? (
      <div className="news-error-container">
        <p>Veri bulunamadı!</p>
      </div>
    ) : data.length === 0 ? (
      <div className="news-error-container">
        <p>Yazı bulunamadı</p>
      </div>
    ) : (
      <div className="haber-card-details-wrapper">
        <div className="haber-card-details-cards">
          {data.map((haber) => {
            const coverImage =
              haber.postimage?.find((img) => img.is_cover_image)?.uploaded_image?.image 
            return (
              <div className="HaberCardDetails-main" key={haber.id}  onClick={() => handleNewsClick(haber.id, haber.header||haber.sub_header)}>
                <div className="HaberCardDetails-grid">
                  <div className="HaberCardDetails-img">
                    <img
                      src={coverImage || Rectangle}
                      alt="Haber"
                      style={{ width: "100%", height: "100%" }}
                    />
                    <OzelHaberImage ReporterImg={haber.reporter__image} />
                  </div>
                  <div className="text-info-main-container">
                    <div className="info-wrapper">
                      <div className="info">
                        <div className="info-item-main-wrapper">
                          <div className="info-item">
                            <FaUser />
                            <span>{haber.editor || name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-info-main-container-desp">
                      <h2>{haber.header}</h2>
                    </div>
                    <div className="haber-card-details-info-sub-header">
                        {haber.sub_header}
                      </div>
                    <div className="info-wrapper">
                      <div className="info">
                        <div className="info-item-more-button-wrapper">
                          <button
                            className="info-item-more-button"
                            onClick={() => handleNewsClick(haber.id, haber.header||haber.sub_header)}
                          >
                            Daha Fazla
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!loading && !error && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
      </div>
    )
  );
};

export default HaberCardDetails;
