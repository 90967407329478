import React from "react"
import "./Socialmedia.css"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share"

function Socialmedia({title, description, content, image}) {
  // Clean up the URL format but keep the hash
  const currentUrl = window.location.href.replace(
    "localhost:3000",
    "kibrisligazetesi.com"
  )

  // Create a clean excerpt from the content
  const getExcerpt = (content, maxLength = 150) => {
    const plainText = content?.replace(/<[^>]+>/g, "") || ""
    return plainText.length > maxLength
      ? `${plainText.substring(0, maxLength)}...`
      : plainText
  }

  const excerpt = getExcerpt(description || content)

  // Format share text differently for each platform
  const facebookShareText = `${title}\n\n${excerpt}`
  const twitterShareText = `${title}\n\n${getExcerpt(
    description || content,
    100
  )}`
  const whatsappShareText = `${title}\n\n${excerpt}\n\n${currentUrl}`
  const telegramShareText = `${title}\n\n${excerpt}\n\n${currentUrl}`

  const hashtags = ["KibrislıGazetesi", "Haber"]

  return (
    <div className="Socialmedia">
      <div className="Socialmedia-slider">
        <FacebookShareButton
          url={currentUrl}
          quote={facebookShareText}
          hashtag="#KibrislıGazetesi"
          className="social-icon facebook"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={currentUrl}
          title={twitterShareText}
          hashtags={hashtags}
          via="kibrisligazetesi"
          className="social-icon twitter"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={currentUrl}
          title={whatsappShareText}
          separator=" "
          className="social-icon whatsapp"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={currentUrl}
          title={telegramShareText}
          className="social-icon telegram"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default Socialmedia
