import React from "react"
import {MdAccessTime} from "react-icons/md"

const timeDifference = (createdAt) => {
  if (!createdAt) return "-"

  const now = new Date()
  const createdDate = new Date(createdAt)
  const diffInSeconds = (now.getTime() - createdDate.getTime()) / 1000

  const oneMinute = 60
  const oneHour = oneMinute * 60
  const oneDay = oneHour * 24
  const oneWeek = oneDay * 7
  const oneMonth = oneDay * 30

  if (diffInSeconds < oneHour) {
    const minutes = Math.floor(diffInSeconds / oneMinute)
    return `${minutes} dk önce`
  } else if (diffInSeconds < oneDay) {
    const hours = Math.floor(diffInSeconds / oneHour)
    return `${hours} saat önce`
  } else if (diffInSeconds < oneWeek) {
    const days = Math.floor(diffInSeconds / oneDay)
    return `${days} gün önce`
  } else if (diffInSeconds < oneMonth) {
    const weeks = Math.floor(diffInSeconds / oneWeek)
    return `${weeks} hafta önce`
  } else {
    const formattedDate = createdDate.toLocaleDateString()
    return ` ${formattedDate}`
  }
}

const TimeAgoDate = {
  fontSize: "14px",
  color: "#868686",
}

const TimeAgoDateContainer = {
  display: "flex", // Flexbox düzeni kullanıyoruz
  alignItems: "center", // Yatayda ortalar
  justifyContent: "flex-end", // Sağ tarafa hizalar
  width: "100%", // Ekran boyutuna göre genişlesin
}

const TimeAgo = ({createdAt}) => {
  return (
    <div style={TimeAgoDateContainer}>
      <MdAccessTime size={19} style={{color: "#868686", marginRight: "5px"}} />{" "}
      {/* İkonun sağında boşluk */}
      <span style={TimeAgoDate}>{timeDifference(createdAt)}</span>
    </div>
  )
}

export default TimeAgo
