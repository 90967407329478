import React, {lazy, Suspense} from "react"
import {useContext} from "react"
import {RelatedContext} from "./Context/RelatedContext"
import {Route, Routes} from "react-router-dom"
import Navbar from "./Components/Navbar/Navbar"
import Footer from "./Components/Footer/Footer"
import Kunye from "./Screens/Kunye/Kunye"
import Aydinlatma from "./Screens/Aydinlatma/Aydinlatma"
import Cerez from "./Screens/Cerez/Cerez"
import Gizlilik_politikasi from "./Screens/Gizlilik_politikasi/Gizlilik_politikasi"
import Iletisim from "./Screens/Iletisim/Iletisim"
import Reklam from "./Screens/Reklam/Reklam"
import YayinIlkeleri from "./Screens/Yayin_ilkeleri/Yayin_ilkeleri"
import Ziyaretci_aydinlatma from "./Screens/Ziyaretci_aydinlatma/Ziyaretci_aydinlatma"
import ScrollToTop from "./Components/ScrollToTop"
import Eczane from "./Screens/Eczane/Eczane"
import Manset from "./Screens/Manset/Manset"
import Popup from "./Components/RelatedComponent/Popup/Popup"
import ShortHaber from "./Screens/ShortHaber/ShortHaber"
import AksaSuperLig from "./Screens/AksaSuperLig/AksaSuperLig"
import Vefatlar from "./Screens/Vefalar/Vefatlar"
import Yazarlarimiz from "./Screens/Yazarlarimiz/Yazarlarimiz"
import YazarDetails from "./Screens/Yazarlarimiz/YazarDetails/YazarDetails"
import HaberDetails from "./Screens/HaberDetails/HaberDetails"
import {disableConsoleInDevelopment} from "./utils/console"
import loadingGif from "./img/kıbrıslı-gazetesi-haber-default-img-min.jpg"
import Muhabirler from "./Screens/Muhabirler/Muhabirler"
const HomePage = lazy(() => import("./Screens/AnaSayfa/HomePage"))
const CategoryPage = lazy(() => import("./Screens/CategoryPage/CategoryPage"))

const App = () => {
  const {Related} = useContext(RelatedContext)
  //  disableConsoleInDevelopment()
  return (
    <Suspense
      fallback={
        <div className="loading-container">
          <img src={loadingGif} alt="Loading..." className="loading-image" />
        </div>
      }
    >
      <ScrollToTop />
      <Navbar />
      {Related.length >= 0 && <Popup />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:categoryId/:categoryName" element={<CategoryPage />} />
        <Route path="/haber/:id" element={<HaberDetails />} />
        <Route path="/haber/:id/:title" element={<HaberDetails />} />
        <Route path="/haber/onizleme/*" element={<HaberDetails />} />
        <Route path="/manset" element={<Manset />} />
        <Route path="/yazarlar" element={<Yazarlarimiz />} />
        <Route path="/yazarlar/:id/:name" element={<YazarDetails />} />
        <Route path="/:id/vefatlar" element={<Vefatlar />} />
        <Route path="/eczane" element={<Eczane />} />
        <Route path="/shorts" element={<ShortHaber />} />
        <Route path="/aksa-super-lig" element={<AksaSuperLig />} />
        <Route path="/kunye" element={<Kunye />} />
        <Route path="/aydinlatma" element={<Aydinlatma />} />
        <Route path="/cerez" element={<Cerez />} />
        <Route path="/gizlilik-politikasi" element={<Gizlilik_politikasi />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/reklam" element={<Reklam />} />
        <Route path="/yayin-ilkeleri" element={<YayinIlkeleri />} />
        <Route path="/muhabirler" element={<Muhabirler />} />
        <Route
          path="/ziyaretci-aydinlatma"
          element={<Ziyaretci_aydinlatma />}
        />
      </Routes>
      <Footer />
    </Suspense>
  )
}

export default App
