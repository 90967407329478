import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, selectedModal }) => {
  if (!isOpen || !selectedModal) return null;

  // Tarihleri formatlamak için yardımcı bir fonksiyon
  const formatDate = (date) => {
    if (!date) return "-"; // Return dash for null/undefined dates
    const deathDate = new Date(date);
    return deathDate.toLocaleDateString("tr-TR"); // 27.09.2024 formatı
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="modal-close-btn" onClick={onClose}>
          X
        </button>

        <div className="modal-image-container">
          <h1>ACI KAYBIMIZ</h1>
          <div className="modal-image-content">
            <div className="testttttt">
              <h2>{selectedModal.fullname}</h2>

              <div className="modal-image-wrapper">
                <img
                  src={selectedModal.image}
                  alt={selectedModal.fullname}
                  className="modal-image"
                />
                <div className="modal-image-dates">
                  <span>
                    {formatDate(selectedModal.birth_date)} /{" "}
                    {formatDate(selectedModal.death_date)}
                  </span>
                </div>
              </div>
              <div className="modal-center-main">
                <div className="modal-center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedModal.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
