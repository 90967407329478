import React from "react"
import "./Navbar.css"
import Topbar from "./Topbar/Topbar"
import logo from "../../img/Group.png"
import Topbarbottom from "./Topbarbottom/Topbarbottom"
import Navmobile from "./Navmobile/Navmobile"
import Wheather from "./Wheather/Wheather"
import {Link} from "react-router-dom"
import EczaneImg from "../../img/eczane-logo-E.png"

const TeamsTable = () => {
  return (
    <div className="Navbar">
      <div className="nav-items">
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="nav-right">
          <div className="eczane">
            <Link to="/eczane" className="linkEczane">
              <img src={EczaneImg} alt={""} className="eczaneler-img" />
              <div className="eczane-text">
                <span>Nöbetçi</span>
                <span>Eczane</span>
              </div>
            </Link>
          </div>
          <div className="div3">
            <Wheather />
          </div>
        </div>
      </div>
      <div className="navwrapper">
        <Topbar />
        <Topbarbottom />
      </div>
      <div className="navmobile">
        <Navmobile />
      </div>
    </div>
  )
}

export default TeamsTable
