// src/PathHelper/PathHelper.js
export const turkceToEnglish = (text) => {
    const trMap = {
      ç: "c",
      Ç: "C",
      ğ: "g",
      Ğ: "G",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ş: "s",
      Ş: "S",
      ü: "u",
      Ü: "U",
    };
    return text
      .replace(/[çğıöşüÇĞİÖŞÜ]/g, (letter) => trMap[letter] || letter)
      .replace(/\s+/g, "-")
      .toLowerCase();
  };
  