import React, {useContext} from "react"
import "./Aydinlatma.css"
import {RelatedContext} from "../../Context/RelatedContext"
import Side from "../../Components/Navbar/PlayersList/Side/Side"
import TitleBox from "../../Components/TitleBox/TitleBox"
import RelatedComponent from "../../Components/RelatedComponent/RelatedComponent"
import TopRelatedCategory from "../../Components/RelatedComponent/TopRelatedCategory"
import SingleRelatedComponent from "../../Components/RelatedComponent/SingleRelatedComponent"
const Left = () => {
  const {Related} = useContext(RelatedContext)
  return (
    <>
      <TopRelatedCategory />
      <div className="aydinlatma-Left">
        <div className="aydinlatma-Left-ad">
          <div className="aydinlatma-Left-content">
            <div>
              <TitleBox title={"Müşteri Aydınlatma Metni"} />
              <div className="aydinlatma-left-line" />
              <h1 className="aydinlatma-Title">
                Kıbrıslı Haber Ajansı A.Ş. Müşteri Aydınlatma Metni
              </h1>
            </div>

            <p className="aydinlatma-left-text">
              Bu Müşteri Aydınlatma
              <strong>
                Metni Yüksel Altan Apartmanı, Mete Adanır Cd-Daire 2, Girne 0392
              </strong>
              adresinde faaliyet gösteren veri sorumlusu Kıbrıslı Haber Ajansı
              A.Ş. (Şirket) tarafından verilen hizmetler kapsamında herhangi bir
              şekilde müşterilerden elde edilen ve/veya edilecek verilerin
              kullanımına ilişkin hüküm ve koşullar ile verilerin işlenme
              şekilleri hakkında müşteriyi aydınlatmak ve bilgilendirmek
              amacıyla hazırlanmıştır. Şirket, kişisel verileri, internet
              siteleri, sosyal medya hesapları, e-posta, posta, call center,
              fax, idari ve adli makamlardan gelen tebligatlar ve sair iletişim
              kanalları aracılığıyla işitsel, elektronik veya yazılı olarak,
              Kanun’da belirtilen kişisel veri işleme şartlarına uygun olarak ve
              bu Metin’de belirtilen hukuki sebepler ve amaçlar doğrultusunda
              toplamaktadır.
            </p>

            <div className="aydinlatma-Left-section">
              <h2 className="aydinlatma-left-subtitle">
                {" "}
                İlgili Kişi, Veri Kategorileri ve Veri Türleri
              </h2>
            </div>
            <div className="aydinlatma-aydınlatma-container">
              <div className="aydinlatma-aydınlatma-row">
                <div className="aydinlatma-aydınlatma-cell">Müşteri</div>
              </div>
              <div className="aydinlatma-aydınlatma-row2">
                <div className="aydinlatma-aydınlatma-cell">Kimlik bilgisi</div>
                <div className="aydinlatma-aydınlatma-cell">
                  İletişim bilgisi
                </div>
                <div className="aydinlatma-aydınlatma-cell">Finans</div>
              </div>
              <div className="aydinlatma-aydınlatma-row4">
                <div className="aydinlatma-aydınlatma-cell">
                  Ad, soyad, TC Kimlik Numarası, vergi numarası
                </div>
                <div className="aydinlatma-aydınlatma-cell">
                  E-posta adresleri, telefon numarası, ikamet adresi
                </div>
                <div className="aydinlatma-aydınlatma-cell">
                  Kredi kartı bilgileri
                </div>
              </div>
            </div>
            <div className="aydinlatma-Left-section">
              <h2 className="aydinlatma-left-subtitle">Hukuki Sebepler</h2>
              <p className="aydinlatma-left-text">
                Yukarıda belirtilen kişisel veriler, veri sahiplerinin bu
                verileri şirkete açıklamasına konu olan ve aşağıda sıralanan
                amaçlarla; Kanun’un 4. maddesinde yer alan ve bu Metin’de de
                tekrarlanan şartlara ve ilkelere uygun olarak; Kanun’un 5. ve 6.
                maddelerinde belirtilen kişisel veri işleme şartları dahlinde
                işlenebilecektir. Her bir veri kategorisi bakımından söz konusu
                olan hukuki sebeplere, aşağıda açıkça yer verilmiştir:
              </p>
            </div>

            <div className="aydinlatma-aydınlatma-container">
              <div className="aydinlatma-aydınlatma-row">
                <div className="aydinlatma-aydınlatma-cell">Müşteri</div>
              </div>

              <div className="aydinlatma-aydınlatma-row2">
                <div className="aydinlatma-aydınlatma-cell">Kimlik bilgisi</div>
                <div className="aydinlatma-aydınlatma-cell">
                  İletişim bilgisi
                </div>
                <div className="aydinlatma-aydınlatma-cell">Finans</div>
              </div>

              <div className="aydinlatma-aydınlatma-row3">
                <div className="aydinlatma-aydınlatma-cell">
                  <ul>
                    <li>
                      <p>
                        Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                        ilgili olması kaydıyla, sözleşmenin taraflarına ait
                        kişisel verilerin işlenmesinin gerekli olması
                      </p>
                    </li>
                    <li>
                      <p>
                        Bir hakkın tesisi, kullanılması veya korunması için veri
                        işlemenin zorunlu olması
                      </p>
                    </li>
                    <li>
                      <p>Kanunlarda açıkça öngörülmesi</p>
                    </li>
                    <li>
                      <p>
                        Şirketin hukuki yükümlülüğünü yerine getrebilmesi için
                        zorunlu olması
                      </p>
                    </li>
                    <li>
                      <p>
                        Veri sahibinin temel hak ve özgürlüklerine zarar
                        vermemek kaydıyla, Şirketin meşru menfaatleri için veri
                        işlemesinin zorunlu olması
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="aydinlatma-Left-section">
              <h2 className="aydinlatma-left-subtitle">
                Kişisel Verilen Hangi Amaçla İşleneceği
              </h2>
              <p className="aydinlatma-left-text">
                Bu Metin kapsamında veri sahipleri 2’nci maddede yer alan
                kişisel verileri, bu Metin’de belirtilen genel şartlara uygun
                şekilde aşağıdaki amaçlarla işlenmektedir:
              </p>
            </div>

            <div className="aydinlatma-aydınlatma-container">
              <div className="aydinlatma-aydınlatma-row">
                <div className="aydinlatma-aydınlatma-cell">Müşteri</div>
              </div>

              <div className="aydinlatma-aydınlatma-row2">
                <div className="aydinlatma-aydınlatma-cell">Kimlik bilgisi</div>
                <div className="aydinlatma-aydınlatma-cell">
                  İletişim bilgisi
                </div>
                <div className="aydinlatma-aydınlatma-cell">Finans</div>
              </div>

              <div className="aydinlatma-aydınlatma-row3">
                <div className="aydinlatma-aydınlatma-cell">
                  <ul>
                    <li>
                      <p>Bilgi güvenliği süreçlerinin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Denetim/etik faaliyetlerin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Erişim yetkilerinin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Hukuk işlerinin takibi ve yürütülmesi</p>
                    </li>
                    <li>
                      <p>İletişim faaliyetlerinin yürütülmesi</p>
                    </li>
                    <li>
                      <p>
                        Müşteri memnuniyetine yönelik anketlerin oluşturulması
                      </p>
                    </li>
                    <li>
                      <p>Sözleşme süreçlerinin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Talep ve şikayetlerin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Ürün/hizmetlerin pazarlama süreçlerinin yürütülmesi</p>
                    </li>
                    <li>
                      <p>Pazarlama analiz çalışmalarının yürütülmesi</p>
                    </li>
                    <li>
                      <p>
                        Related, kampanya, promosyon süreçlerinin yürütülmesi
                      </p>
                    </li>
                    <li>
                      <p>Faaliyetlerin mevzuata uygun yürütülmesi</p>
                    </li>
                    <li>
                      <p>Sözleşme süreçlerinin yürütülmesi</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="aydinlatma-Left-section">
              <h2 className="aydinlatma-left-subtitle">
                Kişisel Verilerin Aktarımı
              </h2>
              <p className="aydinlatma-left-text">
                Şirket, veri sahiplerine ait elde ettiği kişisel verileri
                sunduğu hizmetleri ifa etmek amacıyla ve Metin’de beyan ettiği
                yükümlülükleri yerine getirmek şartıyla yurtiçindeki
                tedarikçilerine aktarabilir. Şirket ayrıca veri sahiplerine ait
                kişisel verileri aşağıdaki amaçlarla ve üçüncü kişilere
                aktarabilir:
              </p>
            </div>

            <div className="aydinlatma-aydınlatma-container">
              <div className="aydinlatma-aydınlatma-row">
                <div className="aydinlatma-aydınlatma-cell">Müşteri</div>
              </div>

              <div className="aydinlatma-aydınlatma-row3">
                <div className="aydinlatma-aydınlatma-cell">
                  <ul>
                    <li>
                      <p>
                        Faaliyetlerin mevzuata uygun yürütülmesi , hukuk
                        işlerinin takibi ve yürütülmesi ile yetkili kişi, kurum
                        ve kuruluşlara bilgi verilmesi amaçlarıyla yetkili kamu
                        kurum ve kuurluşlarıyla kimlik, iletişim ve işlem
                        güvenliği bilgilerinin paylaşılması
                      </p>
                    </li>
                    <li>
                      <p>
                        Saklama ve arşiv faaliyetlerini yürütülmesi amacıyla
                        depolama hizmeti alınan tedarikçiler ile bilgilerin
                        paylaşılması
                      </p>
                    </li>
                    <li>
                      <p>
                        İş faaliyetlerinin yürütülmesi ve denetimi amacıyla iş
                        süreçlerinde hizmetlerinden yararlanılan tedarikçiler
                        ile paylaşılması
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="aydinlatma-Left-section">
              <h2 className="aydinlatma-left-subtitle">
                İlgili Kişinin Hakları
              </h2>
              <p className="aydinlatma-left-text">
                Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
                belirtmek ve kişisel verileriniz üzerindeki haklarınızı
                kullanmak amacıyla,
                <a
                  href="https://www.kibrisligazetesi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="aydinlatma-link"
                >
                  www.kibrisligazetesi.com
                </a>{" "}
                adresinde bulunan
                <u> “İlgili Kişi Başvuru Formu”</u>
                nu doldurarak, şirketin resmi e-mail adresi (
                <a
                  href="mailto:kvkk@kibrisli.com.tr"
                  className="aydinlatma-link"
                >
                  kvkk@kibrisli.com.tr
                </a>{" "}
                veya
                <a
                  href="mailto:kibrisli@hs01.kep.tr"
                  className="aydinlatma-link"
                >
                  {" "}
                  kibrisli@hs01.kep.tr
                </a>
                ) üzerinden güvenli elektronik imza ile veya kişisel e-posta
                adresiniz üzerinden veya Şirketin{" "}
                <strong>
                  Metni Yüksel Altan Apartmanı, Mete Adanır Cd-Daire 2, Girne
                  0392
                </strong>{" "}
                adresine şahsen veya noter vasıtasıyla yazılı başvurarak gerekli
                değişiklik, güncelleme ve/veya silme gibi işlemleri ve ilgili
                talepleri gerçekleştirebilirsiniz.
              </p>
              <p>
                Belirtilen yöntemlerle taleplerinizi bize iletmeniz durumunda
                Şirket, talebinizin niteliğine göre talebi en kısa sürede ve en
                geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
              </p>
              <p>
                Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Şirket
                tarafından Kişisel Verileri Koruma Kurulu’nca belirlenen
                tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri
                sahipleri;
              </p>

              <div className="aydinlatma-haklar">
                <p>
                  <strong>a)</strong> Kişisel veri işlenip işlenmediğini
                  öğrenme,
                </p>
                <p>
                  <strong>b)</strong> Kişisel verileri işlenmişse buna ilişkin
                  bilgi talep etme,
                </p>
                <p>
                  <strong>c)</strong> Kişisel verilerin işlenme amacını ve
                  bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                </p>
                <p>
                  <strong>d)</strong> Yurt içinde veya yurt dışında kişisel
                  verilerin aktarıldığı üçüncü kişileri bilme,
                </p>
                <p>
                  <strong>e)</strong> Kişisel verilerin eksik veya yanlış
                  işlenmiş olması hâlinde bunların düzeltilmesini isteme,
                </p>
                <p>
                  <strong>f)</strong> Kanun’un 7. maddesinde öngörülen şartlar
                  çerçevesinde kişisel verilerin silinmesini veya yok edilmesini
                  isteme,
                </p>
                <p>
                  <strong>g)</strong> (d) ve (e) bentleri uyarınca yapılan
                  işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
                  bildirilmesini isteme,
                </p>
                <p>
                  <strong>h)</strong> İşlenen verilerin münhasıran otomatik
                  sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
                  kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                </p>
                <p>
                  <strong>i)</strong> Kişisel verilerin kanuna aykırı olarak
                  işlenmesi sebebiyle zarara uğraması hâlinde zararın
                  giderilmesini talep etme.
                </p>
              </div>

              <p>
                Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda
                belirtilen haklarınızı kullanmak için yapacağınız ve kullanmayı
                talep ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda;
                talep ettiğiniz hususun açık ve anlaşılır olması, talep
                ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına
                hareket ediyor iseniz bu konuda özel olarak yetkili olmanız ve
                yetkinizi belgelendirilmesi, başvurunun kimlik ve adres
                bilgilerini içermesi ve başvuruya kimliğinizi tevsik edici
                belgelerin eklenmesi gerekmektedir.
              </p>
            </div>
            <div className="aydinlatma-left-RelatedComponent">
              <SingleRelatedComponent />
            </div>
          </div>
          <div className="aydinlatma-side">
            <Side />
          </div>
        </div>
      </div>
    </>
  )
}

export default Left
