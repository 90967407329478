import React, {useContext} from "react"
import RelatedComponent from "./RelatedComponent"
import {RelatedContext} from "../../Context/RelatedContext"
import "./HaberIciRelatedUst.css"

export default function HaberIciRelatedUst() {
  const {Related} = useContext(RelatedContext)

  const addCategory = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 12vw",
  }

  return (
    <div style={addCategory} className="haberIciRelated">
      <div className="haberIciRelated-col">
        <RelatedComponent relatedId={10}  />
        <RelatedComponent relatedId={9}  />
        <RelatedComponent relatedId={8}  />
        <RelatedComponent relatedId={7}  />
        <RelatedComponent relatedId={6}  />
      </div>
    </div>
  )
}
