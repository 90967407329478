import React, { useState, useEffect, useContext } from "react";
import "./RelatedComponent.css";
import defalutrelatedImage from "../../img/relatedAlanı-600x100.png";
import SideDefaultImg from "../../img/435x470.jpg";
import TopAdBiggerImage from "../../img/1200x120.png";
import SliderDefaultImg from "../../img/slider-reklam-alanı.png";
import { Config } from "../../Config/config";
import { RelatedContext } from "../../Context/RelatedContext"; // Fixed case sensitivity

const RelatedComponent = ({ relatedId, children, size }) => {
  const { Related } = useContext(RelatedContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Related.length >= 0) {
      setIsLoading(false);
    }
  }, [Related]);

  if (isLoading) {
    return null;
  }

  const Relate = Related.find((Relate) => Relate.advert_area === relatedId);

  const imageToShow = Relate?.image
    ? Relate.image
    : size === "sidesize"
    ? SideDefaultImg
    : size === "topAdBigger"
    ? TopAdBiggerImage
    : size === "SliderDefaultImg"
    ? SliderDefaultImg
    : defalutrelatedImage;
  
  const redirectUrl = Relate?.redirect_url || "/#/reklam";

  const handleClick = async () => {
    try {
      const response = await fetch(
        `https://api.kibrisligazetesi.com/increase-adv-count/${Relate.id}`,
        {
          method: "GET",
          headers: {Authorization: `Token ${Config.Token}`},
        }
      );
    } catch (error) {
      console.error("Related count alınmıyor:", error);
    }
  };

  return (
    <div className="relatedItem">
      <a
        href={redirectUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="relatedLink"
        onClick={handleClick}
      >
        <img
          src={imageToShow}
          alt={Relate?.header || "Reklam ver"}
          className="relatedImage"
        />
        {children}
      </a>
    </div>
  );
}

export default RelatedComponent;
